import axios from 'axios'
import qs from 'query-string'
import moment from 'moment'

(async () => {

    const parsed = qs.parse(location.search)

    const key = parsed.key
    
    if(!key) return alert('Missing parameter "key"')
    
    let report = await axios.get('https://api.sorryapp.com/v1/pages/c2ed623c/notices', { headers: {
        Authorization: `Bearer ${key}`
    }})
    
    let csv = "data:text/csv;charset=utf-8,Created,Resolved,Time to resolution,Type,State,Subject,Synopsis\r\n"
    
    for(let incident of report.data.response) {

        let createdAt = new Date(incident.created_at)
        let endedAt = new Date(incident.ended_at)
        let timeToResolution = moment(endedAt).diff(moment(createdAt), 'hours', true).toFixed(2) + " hours"

        csv += `${incident.created_at},${incident.ended_at},${timeToResolution},${incident.type},${incident.state},${serialize(incident.subject)},${serialize(incident.synopsis)}\r\n`

    }

    let encodedUri = encodeURI(csv)
    let link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "report.csv")
    document.body.appendChild(link)

    link.click()
    
    document.getElementById('csv').innerHTML = csv

})()

function serialize(str) {
    return str.replace(/,/g, '.').replace(/\n/g, '. ').replace(/\r/g, '. ')
}

function datediff(first, second) {
    return Math.round((second-first)/(1000*60*60*24))
}
